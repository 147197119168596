.details-container {
    flex: 0 0 500px;
    padding: 10px;
    background-color: #fff; /* White background */
    height: 100vh; /* Fixed height of the list container */
  }
  
  .details-container ul {
    list-style: none;
    padding: 0;
  }
  
  .details-container li {
    cursor: pointer;
    margin: 5px 0;
    padding: 8px;
    background-color: #e3f2fd; /* Lighter shade for item background */
    border-radius: 4px;
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .details-container li:hover {
    background-color: #bbdefb; /* Darker shade on hover */
  }

  .details-container h2 {
    color: #2e3f7d; /* Dark green text color */
  }
  
  .details-container button {
    background-color: #3b7a95; /* Material Design success color for the button */
    color: #fff; /* White text color */
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  