.list-container {
    flex: 0 0 100px;
    padding: 10px;
    background-color: #fff; /* White background */
    height: 100vh; /* Fixed height of the list container */
  }
  
  .list-container ul {
    list-style: none;
    padding: 0;
  }
  
  .list-container li {
    cursor: pointer;
    margin: 5px 0;
    padding: 8px;
    background-color: #e3f2fd; /* Lighter shade for item background */
    border-radius: 4px;
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .list-container li:hover {
    background-color: #bbdefb; /* Darker shade on hover */
  }
  