/* Your existing styles... */

.list-and-display-container {
    display: grid;
    grid-template-rows: auto auto auto 1fr; /* Three rows for header, welcome message, logout button, and lists */
    place-items: center; /* Center the content horizontally and vertically */
    height: 100vh; /* Set the container to full height of the viewport */
  }
  
  img {
    max-width: 200px;
    max-height: 200px;
  }
  
  p {
    margin: 10px 0;
  }
  
  button {
    padding: 10px 15px;
    font-size: 16px;
    background-color: #2196F3;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  button:hover {
    background-color: #c08a15;
  }
  
  /* Your existing styles... */
  