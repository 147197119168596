/* MainPage.css */
.container {
    display: grid;
    grid-template-rows: auto auto; /* Two rows: auto for the header, 1fr for the content */
    grid-template-columns: auto auto; /* Two columns: equal width for the logo and content */
  }
  
  .header {
    grid-row: 1;
    grid-column: span 2; /* Span across both columns */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin-left: 10%;
    grid-template-columns: 1fr 1fr;
  }
  
  .logo {
    grid-column: 1;
    max-width: 250px; /* Adjust the width as needed */
  }
  
  .login-signup {
    display: flex;
    align-items: start;
    grid-column: 2;
  }
  
/* MainPage.css */
/* ... (existing styles) */

.login-signup button {
    margin-left: 10px;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #2196F3;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .login-signup button:hover {
    background-color: #1565c0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  
  .content {
    grid-row: 2;
    grid-column: span 2; /* Span across both columns */
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns for text-explanation and image-container */
  }
  
  .text-explanation {
    grid-column: 1;
    font-family: 'Roboto Mono', sans-serif;
    margin-left: 25%;
  }
  
  .image-container {
    grid-column: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(38, 38, 138, 0.872);
  }
  
  .main-image {
    max-width: 800px; /* Adjust the width as needed */
    max-height: 600px; /* Adjust the height as needed */
  }
  